.wrapper_logo {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px;
}

.logo_center {
    width: 250px;
}

.image_marina {
    background-image: url('../images/3.png');
    height: 212.8px;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    backdrop-filter: blur(90px);
    background-color: rgba(255, 255, 255, 0.5);
}



.container_info {
    padding: 10px 20px;
    position: relative;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.title_info {
    font-size: 22px;
    color: black;
    font-weight: 600;
    line-height: 1.34;
    text-transform: uppercase;

}

.wrapper_icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f7fa;
}

.row_info_max {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.about-info {
    text-align: justify;
    font-size: 13px;

}

.about-info-window {
    text-align: justify;
    font-size: 14px;
}

.subtitle-info {
    font-size: 20px;
}

.subtitle-info-window {
    font-size: 16px;
}

.div-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: 80px;
    height: 80px;
    border-radius: 10px;
    border: solid 1px var(--slate-grey);
    padding: 0px 10px;
    box-sizing: border-box;
    margin: 12px 12px 0px 0px;
}

.div-service-window {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: 40px;
    height: 45px;
    border-radius: 10px;
    border: solid 1px var(--slate-grey);
    padding: 0px 10px;
    box-sizing: border-box;
    margin: 12px 12px 0px 0px;
}

.styledbutton {
    padding: 12px;
    min-height: 12px;
    margin-top: 18px;
    max-width: 100%;
}

.styledbutton-window {
    padding: 12px;
    min-height: 12px;
    margin-top: 18px;
    max-width: 100%;
    font-size: 14px
}