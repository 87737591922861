.infoStyle {
    max-width: 400px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: auto;
}

.infoStyle h2 {
    font-size: 22px;
}

.infoStyle p {
    font-size: 14px;
}

.button_infomaps {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    background-color: var(--brand);
    text-align: center;
    background-image: url('../images/Button-Wave.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    padding: 10px 25px;
    font-size: 18px;
    transition: background-color .25s;
}

@media screen and (max-width: 479px) {
    .button_infomaps {
        padding: 10px 25px;
        font-size: 14px;
    }

    .infoStyle h2 {
        font-size: 18px;
    }

    .infoStyle p {
        font-size: 14px;
    }
}