.marina-info {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.marina-header {
    text-align: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.marina-name {
    margin: 0;
    color: #333;
}

.marina-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.marina-reviews {
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 15px;
}

.marina-location {
    color: #666;
    margin-top: 5px;
}

.marina-details {
    text-align: left;
}

.amenities-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
}

.amenities-list li {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
}

.marina-about {
    line-height: 1.6;
    color: #333;
    margin-top: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
    .marina-info {
        padding: 10px;
    }

    .amenities-list {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
}

.imageStyleMarina {
    width: '50%',
}