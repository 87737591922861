.banner {
    min-height: 48px;
    background-color: var(--brand);
    color: #fff;
    background-image: url('../images/Banner-Wave.svg');
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    padding: 5px 3%;
    font-size: 15px;
    display: flex;
    position: relative;
    z-index: 10;
}

.banner-wrap {
    width: 100%;
    max-width: 1000px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.notice-text {
    color: rgba(255, 255, 255, .8);
    font-family: "Inter", sans-serif;
    font-weight: 400;
}

@media screen and (max-width: 479px) {

    .banner {
        display: none;
    }

}