.navbar {
    position: relative;
    padding: 30px auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    text-decoration: none;
    list-style: none;
}

.header-nav {
    position: relative;
    z-index: 5;
    padding: 30px 0;
}


.navbar .logo {
    width: 246px;
    max-width: 479px;
}

.navbar .nav-links ul {
    display: flex;

}

.navbar .nav-links ul li {
    margin: 0 16px;
    font-size: 16px;
}

.navbar .nav-links ul li.active a {
    color: var(--brand);
    opacity: .8;
}

.a-css {
    color: #28224b;
    opacity: .8;
}

.a-css:hover {
    opacity: 1;
}

.navbar .menu-hamburger {
    width: 20px;
}

.menu-button {
    position: absolute;
    top: 25px;
    right: 10px;
    display: none;
}


@media screen and (max-width: 991px) {
    .navbar {
        padding: 0;
    }

    .top-side {
        position: relative;
        background-color: #ffffff;
        padding: 45px;
        width: 100%;

    }

    .header-nav {
        padding: 0 0;
    }


    .nav-links {
        position: absolute;
        top: 85px;
        left: 0;
        width: 100%;
        height: 300px;
        display: flex;
        margin-top: -100%;
        align-items: center;
        background-color: white;
        transition: all 0.5s ease;
        z-index: -2;
    }

    .nav-links.mobile-menu {
        margin-top: 0%;
    }

    .nav-links ul {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 0px 16px
    }

    .navbar .nav-links ul li {
        margin: 20px 0px;
        font-size: 16px;
    }

    .navbar .menu-hamburger {
        display: block;
    }

    .navbar .logo {
        position: absolute;
        width: 200px;
        top: 35px;
        left: 10px;
    }

    .menu-button {
        min-height: 55px;
        min-width: 55px;
        background-color: rgba(126, 130, 143, .1);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        padding: 16px;
        display: flex;
    }

    .menu-button.menu-button-clicked {
        background-color: var(--brand);
    }
}